import React from "react";
import SiteHead from "../component/head/SiteHead";
import { EntityType } from "../redux/action/CurrentActions";
import { getIsChromeless } from "../redux/action/UIActions";
import { fetchEntityService } from "../service/FetchEntityService";
import { logError } from "../service/ServiceUtil";

const Home = ({ content, channel, requestUrl }) => {
  return (
    <div suppressHydrationWarning={true}>
      <SiteHead content={content} channel={channel} requestUrl={requestUrl} />
    </div>
  );
};

export default Home;

const supportedPermissionsList = [
  "camera",
  "microphone",
  "fullscreen",
  "autoplay",
  "clipboard-read",
  "clipboard-write",
  "speaker",
  "speaker-selection",
  "web-share",
  "display-capture",
  "screen-wake-lock",
  "payment",
  "document-domain",
  "identity-credentials-get",
  "idle-detection",
  "local-fonts",
  "otp-credentials",
  "picture-in-picture",
  "publickey-credentials-create",
  "publickey-credentials-get",
  "serial",
  "storage-access",
  "usb",
  "window-management",
];
const unsupportedPermissionsList = [
  "accelerometer",
  "ambient-light-sensor",
  "attribution-reporting",
  "bluetooth",
  "browsing-topics",
  "compute-pressure",
  "encrypted-media",
  "gamepad",
  "geolocation",
  "gyroscope",
  "magnetometer",
  "midi",
  "xr-spatial-tracking",
];
const getPermissionsHeaderValue = () => {
  const supportedPermissions = supportedPermissionsList.join("=*, ") + "=*, ";
  const unsupportedPermissions =
    unsupportedPermissionsList.join("=(), ") + "=()";
  return `${supportedPermissions} ${unsupportedPermissions}`;
};

const getCSPDomainList = (query, share) => {
  const list = [];
  if (share && getIsChromeless(query)) {
    list.push("*"); //TODO: Add more logic/support for Slack when known
    list.push("'self'");
    list.push("*.pixelmixer.com");
    list.push("*.slack.com");
  } else {
    list.push("*"); //TODO: Add more logic/support for Slack when known
    list.push("'self'");
    list.push("*.pixelmixer.com");
    list.push("*.slack.com");
    if (share?.cspDomain) list.push(`*.${share.cspDomain}`);
  }
  return list;
};

export const getServerSideProps = async ({ req, res, query }) => {
  res.setHeader(
    "Cache-Control",
    "private, no-cache, no-store, must-revalidate"
  );
  res.setHeader("Permissions-Policy", getPermissionsHeaderValue());
  //Set via cloudfront response header policy. no need to do here:
  // res.setHeader(
  //   "Content-Security-Policy",
  //   "frame-ancestors 'self' *.pixelmixer.com"
  // );
  // res.setHeader("X-Robots-Tag", "noindex, nofollow");

  let contentAndChannelShareObject;
  try {
    contentAndChannelShareObject = await getContentAndChannelShares(req);
    if (contentAndChannelShareObject) {
      const { channelShare, contentShare } = contentAndChannelShareObject;
      //TODO: Find out the slack embed domain and remove *
      res.setHeader(
        "Content-Security-Policy",
        `frame-ancestors ${getCSPDomainList(
          query,
          channelShare || contentShare
        ).join(" ")}`
      );
    }
  } catch (e) {
    logError("getServerSideProps", e);
  }
  return {
    props: {
      time: new Date().toISOString(),
      ...contentAndChannelShareObject,
    },
  };
};

const getContentAndChannelShares = async (req) => {
  const shareCodesObj = getShareCodesObject(req);
  const baseUrl = `https://${req.headers.host}`;
  const requestUrl = baseUrl + req.url;
  const getState = () => ({
    login: {
      baseUrl,
    },
  });
  if (shareCodesObj) {
    const urlArray = req.url.substring(1, req.url.indexOf("?")).split("/");
    if (urlArray[0] === "video" || urlArray[0] === "shares") {
      const contentShare = await fetchEntityService.fetchEntity(
        null,
        getState,
        EntityType.ContentShare,
        null,
        null,
        { ...shareCodesObj, contentId: urlArray[1] }
      );
      return {
        contentShare,
        content: contentShare.content,
        requestUrl,
      };
    } else if (urlArray[0] === "channel") {
      if (urlArray.length === 4) {
        const channelShare = await fetchEntityService.fetchEntity(
          null,
          getState,
          EntityType.ChannelShare,
          null,
          null,
          { ...shareCodesObj, channelId: urlArray[1] }
        );
        const content = await fetchEntityService.fetchEntity(
          null,
          getState,
          EntityType.Content,
          urlArray[3],
          null,
          shareCodesObj
        );
        return {
          channelShare,
          content,
          requestUrl,
        };
      } else {
        const channelShare = await fetchEntityService.fetchEntity(
          null,
          getState,
          EntityType.ChannelShare,
          null,
          null,
          { ...shareCodesObj, channelId: urlArray[1] }
        );
        return {
          channelShare,
          channel: channelShare.channel,
          requestUrl,
        };
      }
    }
  }
};

const getShareCodesObject = (req) => {
  const shareCode = getQueryParam(req, "shareCode");
  const channelShareCode = getQueryParam(req, "channelShareCode");
  if (shareCode) return { shareCode };
  else if (channelShareCode) return { channelShareCode };
};

const getQueryParam = (req, queryParam) => {
  const url = req.url;
  const params =
    url.indexOf("?") > 0
      ? new URLSearchParams(url.substring(url.indexOf("?"), url.length))
      : null;
  if (params) return params.get(queryParam);
};
